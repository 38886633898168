//
//
//
//
//
//
export default {
  props: {
    direction: {
      type: String,
      required: true
    },
    args: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    changeCurrentOrder: function changeCurrentOrder(order) {
      console.log(this.args);
      this.$emit('changeOrder', order, this.args);
    }
  }
};