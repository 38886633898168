var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s16-ql__wrapper" }, [
    _c("div", { staticClass: "s16-ql__content" }, [
      _c("div", { staticClass: "s16-ql__header" }, [
        _c("div", { staticClass: "s16-ql__questionType-wrapper" }, [
          _c(
            "span",
            {
              class: _vm.styleMap["2D"],
              on: {
                click: function($event) {
                  return _vm.problemSelect("2D")
                }
              }
            },
            [_vm._v("2D问题")]
          ),
          _c(
            "span",
            {
              class: _vm.styleMap["3D"],
              on: {
                click: function($event) {
                  return _vm.problemSelect("3D")
                }
              }
            },
            [_vm._v("3D问题")]
          )
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.bodyLoading,
              expression: "bodyLoading"
            }
          ],
          staticClass: "s16-ql__body"
        },
        [
          _c(
            "div",
            { staticClass: "s16-ql__selectors" },
            [
              _c("div", { staticClass: "s16-ql__left-selector" }, [
                _c(
                  "div",
                  { staticClass: "s16-ql__selector" },
                  [
                    _c("span", { staticClass: "s16-ql__label-selector" }, [
                      _vm._v("审核时间:")
                    ]),
                    _c("el-date-picker", {
                      attrs: {
                        size: "small",
                        type: "daterange",
                        align: "center",
                        "unlink-panels": "",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.dateList,
                        callback: function($$v) {
                          _vm.dateList = $$v
                        },
                        expression: "dateList"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.exportLoading
                  },
                  on: {
                    click: function($event) {
                      _vm.centerDialogVisible = true
                    }
                  }
                },
                [_vm._v("导出excel")]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "导出excel",
                    visible: _vm.centerDialogVisible,
                    width: "400px",
                    center: ""
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.centerDialogVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "f-center" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "PAGE" },
                          model: {
                            value: _vm.radio,
                            callback: function($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio"
                          }
                        },
                        [_vm._v("按页导出")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "ALL" },
                          model: {
                            value: _vm.radio,
                            callback: function($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio"
                          }
                        },
                        [_vm._v("全部导出")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.centerDialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.exportLibExcel(_vm.radio)
                            }
                          }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticClass: "s16-ql__table"
            },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticClass: "is-grey",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "highlight-current-row": "",
                    size: "medium",
                    "row-key": "questionId"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "50",
                      span: 1,
                      "reserve-selection": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      width: "50",
                      prop: "sequence",
                      span: 1,
                      "show-overflow-tooltip": "",
                      "column-key": "sequence"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "mr2" }, [_vm._v("序号")])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "专业",
                      align: "center",
                      prop: "problemSpec",
                      width: "90",
                      span: 1,
                      "show-overflow-tooltip": "",
                      "column-key": "problemSpec"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "mutil-selector" },
                              [
                                _c("sort", {
                                  attrs: {
                                    args: ["problemSpecOrder"],
                                    direction: _vm.getOrderName(
                                      _vm.problemSpecOrder
                                    )
                                  },
                                  on: {
                                    changeOrder: _vm.changeColumnOrderByOption
                                  }
                                }),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      width: "260",
                                      trigger: "click"
                                    },
                                    model: {
                                      value: _vm.problemSpecVisible,
                                      callback: function($$v) {
                                        _vm.problemSpecVisible = $$v
                                      },
                                      expression: "problemSpecVisible"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-header",
                                        attrs: { slot: "reference" },
                                        slot: "reference"
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: _vm.searchProblemSpec
                                                ? "筛选专业为" +
                                                  _vm.searchProblemSpec
                                                : "暂无筛选专业",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "mr2" },
                                                [_vm._v("专业")]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-caret-bottom"
                                              })
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "s16-ql__header-table" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value: _vm.searchProblemSpec,
                                              callback: function($$v) {
                                                _vm.searchProblemSpec = $$v
                                              },
                                              expression: "searchProblemSpec"
                                            }
                                          },
                                          _vm._l(_vm.ALL_SPEC, function(item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                label: item,
                                                value: item
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目名称",
                      align: "center",
                      prop: "prjName",
                      span: 2,
                      "show-overflow-tooltip": "",
                      "column-key": "prjName"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "mutil-selector" },
                              [
                                _c("sort", {
                                  attrs: {
                                    args: ["prjNameOrder"],
                                    direction: _vm.getOrderName(
                                      _vm.prjNameOrder
                                    )
                                  },
                                  on: {
                                    changeOrder: _vm.changeColumnOrderByOption
                                  }
                                }),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      width: "260",
                                      trigger: "click"
                                    },
                                    model: {
                                      value: _vm.prjNameVisible,
                                      callback: function($$v) {
                                        _vm.prjNameVisible = $$v
                                      },
                                      expression: "prjNameVisible"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-header",
                                        attrs: { slot: "reference" },
                                        slot: "reference"
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: _vm.searchPrjName
                                                ? "筛选项目名称为" +
                                                  _vm.searchPrjName
                                                : "暂无筛选项目",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "mr2" },
                                                [_vm._v("项目名称")]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "search-icon el-icon-search"
                                              })
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "s16-ql__header-table" },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "small",
                                            placeholder: "请输入项目名称",
                                            "prefix-icon": "el-icon-search"
                                          },
                                          model: {
                                            value: _vm.searchPrjName,
                                            callback: function($$v) {
                                              _vm.searchPrjName = $$v
                                            },
                                            expression: "searchPrjName"
                                          }
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.search("PrjName")
                                              }
                                            }
                                          },
                                          [_vm._v("搜索")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "问题描述",
                      align: "center",
                      prop: "problemDesc",
                      span: 2,
                      "show-overflow-tooltip": "",
                      "column-key": "problemDesc"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  width: "260",
                                  trigger: "click"
                                },
                                model: {
                                  value: _vm.problemDescVisible,
                                  callback: function($$v) {
                                    _vm.problemDescVisible = $$v
                                  },
                                  expression: "problemDescVisible"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "search-header",
                                    attrs: { slot: "reference" },
                                    slot: "reference"
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.searchProblemDesc
                                            ? "筛选问题描述为" +
                                              _vm.searchProblemDesc
                                            : "暂无筛选问题描述",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _c("span", { staticClass: "mr2" }, [
                                            _vm._v("问题描述")
                                          ]),
                                          _c("i", {
                                            staticClass:
                                              "search-icon el-icon-search"
                                          })
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "s16-ql__header-table" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入问题描述",
                                        "prefix-icon": "el-icon-search"
                                      },
                                      model: {
                                        value: _vm.searchProblemDesc,
                                        callback: function($$v) {
                                          _vm.searchProblemDesc = $$v
                                        },
                                        expression: "searchProblemDesc"
                                      }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.search("ProblemDesc")
                                          }
                                        }
                                      },
                                      [_vm._v("搜索")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm.is3D
                    ? _c("el-table-column", {
                        attrs: {
                          label: "所属空间",
                          width: "100",
                          align: "center",
                          prop: "problemRoom",
                          span: 1,
                          "show-overflow-tooltip": "",
                          "column-key": "problemRoom"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "mutil-selector" },
                                    [
                                      _c("sort", {
                                        attrs: {
                                          args: ["problemRoomOrder"],
                                          direction: _vm.getOrderName(
                                            _vm.problemRoomOrder
                                          )
                                        },
                                        on: {
                                          changeOrder:
                                            _vm.changeColumnOrderByOption
                                        }
                                      }),
                                      _c("span", { staticClass: "mr2" }, [
                                        _vm._v("所属空间")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1773535114
                        )
                      })
                    : _vm._e(),
                  _vm.is3D
                    ? _c("el-table-column", {
                        attrs: {
                          label: "所属构件",
                          width: "120",
                          align: "center",
                          prop: "problemComponent",
                          span: 1,
                          "show-overflow-tooltip": "",
                          "column-key": "problemComponent"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "mutil-selector" },
                                    [
                                      _c("sort", {
                                        attrs: {
                                          args: ["problemComponentOrder"],
                                          direction: _vm.getOrderName(
                                            _vm.problemComponentOrder
                                          )
                                        },
                                        on: {
                                          changeOrder:
                                            _vm.changeColumnOrderByOption
                                        }
                                      }),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            width: "260",
                                            trigger: "click"
                                          },
                                          model: {
                                            value: _vm.componentVisible,
                                            callback: function($$v) {
                                              _vm.componentVisible = $$v
                                            },
                                            expression: "componentVisible"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "search-header",
                                              attrs: { slot: "reference" },
                                              slot: "reference"
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: _vm.searchComponent
                                                      ? "筛选所属构件为" +
                                                        _vm.searchComponent
                                                      : "暂无筛选所属构件",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      { staticClass: "mr2" },
                                                      [_vm._v("所属构件")]
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "search-icon el-icon-search"
                                                    })
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "s16-ql__header-table"
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  size: "small",
                                                  placeholder: "请输入所属构件",
                                                  "prefix-icon":
                                                    "el-icon-search"
                                                },
                                                model: {
                                                  value: _vm.searchComponent,
                                                  callback: function($$v) {
                                                    _vm.searchComponent = $$v
                                                  },
                                                  expression: "searchComponent"
                                                }
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.search(
                                                        "Component"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("搜索")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2327189724
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "问题类型",
                      align: "center",
                      width: "150",
                      prop: "problemType",
                      span: 2,
                      "show-overflow-tooltip": "",
                      "column-key": "problemType"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "mr2" }, [
                              _vm._v("问题类型")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "问题状态",
                      align: "center",
                      width: "90",
                      prop: "problemStatus",
                      span: 1,
                      "show-overflow-tooltip": "",
                      "column-key": "problemStatus"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "mutil-selector" },
                              [
                                _c("sort", {
                                  attrs: {
                                    args: ["problemStatusOrder"],
                                    direction: _vm.getOrderName(
                                      _vm.problemStatusOrder
                                    )
                                  },
                                  on: {
                                    changeOrder: _vm.changeColumnOrderByOption
                                  }
                                }),
                                _c("span", { staticClass: "mr2" }, [
                                  _vm._v("问题状态")
                                ])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "问题来源",
                      align: "center",
                      width: "90",
                      prop: "problemSource",
                      span: 2,
                      "show-overflow-tooltip": "",
                      "column-key": "problemSource"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "mr2" }, [
                              _vm._v("问题来源")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核人",
                      align: "center",
                      width: "90",
                      prop: "problemReviewerName",
                      span: 1,
                      "show-overflow-tooltip": "",
                      "column-key": "problemReviewerName"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "mutil-selector" },
                              [
                                _c("sort", {
                                  attrs: {
                                    args: ["problemReviewerNameOrder"],
                                    direction: _vm.getOrderName(
                                      _vm.problemReviewerNameOrder
                                    )
                                  },
                                  on: {
                                    changeOrder: _vm.changeColumnOrderByOption
                                  }
                                }),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      width: "260",
                                      trigger: "click"
                                    },
                                    model: {
                                      value: _vm.problemReviewerNameVisible,
                                      callback: function($$v) {
                                        _vm.problemReviewerNameVisible = $$v
                                      },
                                      expression: "problemReviewerNameVisible"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-header",
                                        attrs: { slot: "reference" },
                                        slot: "reference"
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: _vm.searchProblemReviewerName
                                                ? "筛选审核人为" +
                                                  _vm.searchProblemReviewerName
                                                : "暂无筛选审核人",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "mr2" },
                                                [_vm._v("审核人")]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "search-icon el-icon-search"
                                              })
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "s16-ql__header-table" },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "small",
                                            placeholder: "请输入审核人",
                                            "prefix-icon": "el-icon-search"
                                          },
                                          model: {
                                            value:
                                              _vm.searchProblemReviewerName,
                                            callback: function($$v) {
                                              _vm.searchProblemReviewerName = $$v
                                            },
                                            expression:
                                              "searchProblemReviewerName"
                                          }
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.search(
                                                  "ProblemReviewerName"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("搜索")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核时间",
                      align: "center",
                      width: "150",
                      prop: "checkTime",
                      span: 2,
                      "show-overflow-tooltip": "",
                      "column-key": "checkTime"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "mutil-selector" },
                              [
                                _c("sort", {
                                  attrs: {
                                    args: ["checkTimeOrder"],
                                    direction: _vm.getOrderName(
                                      _vm.checkTimeOrder
                                    )
                                  },
                                  on: {
                                    changeOrder: _vm.changeColumnOrderByOption
                                  }
                                }),
                                _c("span", { staticClass: "mr2" }, [
                                  _vm._v("审核时间")
                                ])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "s16-ql__footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: _vm.page.layout,
              "page-size": _vm.page.pageSize,
              "current-page": _vm.page.pageNo,
              total: _vm.page.totalCount
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }