var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "com_sort" }, [
    _c("i", {
      staticClass: "el-icon-caret-top bottomBias",
      class: _vm.direction == "1" ? "active" : "",
      on: {
        click: function($event) {
          return _vm.changeCurrentOrder("1")
        }
      }
    }),
    _c("i", {
      staticClass: "el-icon-caret-bottom topBias",
      class: _vm.direction == "2" ? "active" : "",
      on: {
        click: function($event) {
          return _vm.changeCurrentOrder("2")
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }